import Apiservice from '@/core/services/api.service';
import {API_URL} from '../../../common/config.js'

const apiService = Apiservice;


export default class SiteSettingService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/site-setting`;
      }

      paginate() {
            let url = `${this.#api}`;
            return apiService.get(url);
      }

      all() {
            let url = `${this.#api}`;
            return apiService.get(url);
      }

      update(id, data) {
            // console.log(data)
            let url = `${this.#api}/${id}/update`
            return apiService.post(url, data);

      }

      store(data) {
            let url = `${this.#api}`
            return apiService.post(url, data);

      }

      show() {
            let url = `${this.#api}`
            return apiService.get(url);
      }
      getColorsVariables() {
            let url = `${API_URL}site-setting/colors`
            return apiService.get(url);
      }

      delete(id) {
            let url = `${this.#api}/${id}`
            return apiService.delete(url);
      }

      uploadTestImage(data) {
            let url = `${API_URL}user/site-setting/s3/test`
            return apiService.post(url, data);
      }

      educationalOrganisation(uniqueId) {
            let url = `https://yelko.com/api/educational-organisation/${uniqueId}`
            return apiService.get(url);
      }


}

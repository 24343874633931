<template>
  <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
    <div class="kt-portlet">

      <div class="kt-portlet__body kt-portlet__body--fit">
        <v-card>
          <v-card-text>
            <div class="kt-invoice-2">
              <div class="kt-invoice__head">
                <div class="kt-invoice__container">
                  <div class="kt-invoice__brand">
                <span class="kt-invoice__title">INVOICE <br>
                <span class="kt-invoice__desc" v-if="data.request_form =='NP'">
                    <span>TAX NO. {{ data.country_config.tax_no }}</span>
                 </span>
                </span>

                    <div href="#" class="kt-invoice__logo">
                      <a href="#" v-if="setting.logo_path && setting.logo_path['real']">
                        <img :src="setting.logo_path['real']"/>
                      </a>
                      <span class="kt-invoice__desc">
                    <span v-if="setting.college_name">{{ setting.college_name }}</span>
                    <span v-if="setting.college_email">{{ setting.college_email }}</span>
                    <span
                        v-if="setting.college_phone">{{
                        setting.college_phone
                      }}</span>
                  </span>
                    </div>
                  </div>
                  <div class="kt-invoice__items">
                    <div class="kt-invoice__item">
                      <span class="kt-invoice__subtitle">DATE</span>
                      <span class="kt-invoice__text">{{ invoice.created_at }}</span>
                    </div>

                    <div class="kt-invoice__item">
                      <span class="kt-invoice__subtitle">INVOICE NO.</span>
                      <span class="kt-invoice__text"> {{ invoice.invoice_no }}</span>
                    </div>
                    <div class="kt-invoice__item">
                      <span class="kt-invoice__subtitle">INVOICE TO.</span>
                      <span class="kt-invoice__text">
                                            <p>
                                              {{ invoice.invoiced_to }}

                                            </p>
                                             <p>
                                              {{ invoice.invoice_address }}
                                             </p>
                                    </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="kt-invoice__body">
                <div class="kt-invoice__container">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                      <tr>
                        <th>DESCRIPTION</th>
                        <th></th>
                        <th>RATE</th>
                        <th>AMOUNT</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item,index) in invoice.items" :key="index">
                        <td v-text="item.title"></td>
                        <td></td>
                        <td>Rs.{{ item.unit_price }}</td>
                        <td
                            class="kt-font-primary kt-font-lg"
                        >Rs.{{ item.item_total }}
                        </td>
                      </tr>
                      <tr>
                        <td>Sub Total</td>
                        <td></td>
                        <td></td>
                        <td> Rs. {{ invoice.sub_total }}</td>
                      </tr>
                      <tr v-if="invoice.tax_total">
                        <td> TAX / VAT</td>
                        <td></td>
                        <td></td>
                        <td> Rs.{{ invoice.tax_total }}</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td></td>
                        <td></td>
                        <td> Rs.{{ invoice.invoice_total }}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="kt-invoice__footer">
                <div class="kt-invoice__container">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                      <tr>
                        <!--                    <th>Payment Method</th>-->
                        <th>PayerName</th>
                        <th>Paid Date</th>
                        <th>TOTAL AMOUNT</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <!--                    <td v-if="invoice">{{ invoice.payment_method }}</td>-->
                        <td v-if="invoice">{{ invoice.invoiced_to }}</td>
                        <td v-if="invoice">{{ invoice.created_at }}</td>
                        <td class="kt-font-primary kt-font-xl kt-font-boldest">Rs.{{ invoice.invoice_total }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </div>

    </div>
  </div>

</template>
<script>

import InvoiceService from "@/core/services/invoice/InvoiceService";
import SiteSettingService from "@/core/services/site-setting/SiteSettingService";

const invoiceService = new InvoiceService();
const settingService = new SiteSettingService();
export default {
  name: "InvoiceDetail",
  data() {
    return {
      invoice: {},
      setting: {},
      data: {}
    };
  },
  methods: {
    getSetting() {
      settingService.getColorsVariables().then(response => {
        this.setting = response.data
      })
    },
    getBillings() {
      invoiceService.show(this.invoice_id).then(response => {
        this.invoice = response.data.invoice;
      });
    }
  },
  computed: {
    invoice_id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.getBillings();
    this.getSetting();
  }
};
</script>